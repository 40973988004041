@import '@/_styles/import';
$root: '.PackageItineraryResults';
$promobannerplace-offset-desktop: 50px;
$promobannerplace-offset-tablet: 40px;
$promobannerplace-offset-mobile: 10px;

#{$root} {
  --adv-filter-header-height: #{rem(90)};
  --adv-filter-body-gap-top: #{rem(0)};
  --adv-filter-body-gap-bottom: #{rem(30)};
  --adv-filter-footer-height: #{rem(90)};
  --adv-filter-footer-btn-height: #{rem(50)};
  --packageitineraryresults-body-max-width: 1180px;
  --packageitineraryresults-body-padding: 0;

  &__content {
    overflow: hidden;
  }

  &__body {
    position: relative;
    max-width: var(--packageitineraryresults-body-max-width);
    margin: 30px auto 150px;
    padding: var(--packageitineraryresults-body-padding);

    .PromoBannerPlace:not(:empty) {
      width: 100%;
      margin: 0 auto 30px;

      @include media-breakpoint-up(md) {
        margin-bottom: $promobannerplace-offset-tablet;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: $promobannerplace-offset-desktop;
      }

      @include media-breakpoint-down(sm) {
        width: 65%;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }

    .PackageCard + .PromoBannerPlace:not(:empty) {
      @include media-breakpoint-up(md) {
        margin-top: 10px;
      }
    }
  }

  &__warningBox {
    margin: 0 0 30px;
  }

  &__footer {
    height: 28px;
    margin: 70px auto 0;

    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    color: $grey-text;
    text-align: center;
    letter-spacing: 0;
  }

  .NewResultRefinements__newFilterContainer .secondaryContainer .Icon {
    transform: scaleX(-1) rotate(0deg);
    padding-top: 0;
    background-color: transparent;

    @media (min-width: $container-lg1-width) {
      width: 24px;
      height: 24px;
    }
  }

  .offset-reset-icon {
    position: relative;
    top: 12px;

    @media (max-width: $container-lg-width-1024) {
      top: 6px;
    }
  }

  #AccessibilityRefinementPackages {
    .form-group {
      display: flex;
      justify-content: center;

      .form-check {
        padding-bottom: 20px;

        .form-check-input[type='checkbox']:not(:checked) ~ label::before {
          border-color: $gray-510;
        }
      }
    }
  }

  .FooterActionBar {
    position: relative;

    &::before {
      pointer-events: none;
      content: '';

      position: absolute;
      z-index: 1;
      top: -50px;
      right: 0;
      left: 0;

      display: block;

      width: 100%;
      height: 50px;

      background: linear-gradient(0deg, $white, transparent);

      @include media-breakpoint-up(lg) {
        top: -75px;
        height: 75px;
      }
    }
  }

  @media (max-width: $container-lg1-min-width) {
    --packageitineraryresults-body-max-width: 1087px;
  }

  @media (max-width: $container-md-max-width) {
    --packageitineraryresults-body-max-width: 820px;
  }

  @media (max-width: $container-sm-width-768) {
    --packageitineraryresults-body-max-width: 690px;
  }

  @media (max-width: $container-xs-width) {
    --packageitineraryresults-body-max-width: 380px;

    margin: 30px auto 0;
  }

  @media (max-width: $container-xxs-width) {
    --packageitineraryresults-body-max-width: 380px;
  }

  @media (max-width: $container-xxxs-width) {
    --packageitineraryresults-body-max-width: 335px;
  }

  @include media-breakpoint-between(xs, lg) {
    --packageitineraryresults-body-padding: 0 20px;
  }

  @include media-breakpoint-down(sm) {
    --adv-filter-header-height: #{rem(60)};
    --adv-filter-body-gap-top: #{rem(30)};
  }
}

@include media-breakpoint-up(md) {
  .-refinementOpen {
    #{$root}__content {
      position: relative;

      &::before {
        content: '';

        position: absolute;
        z-index: 6;
        top: 0;

        width: 100%;
        height: 100%;

        opacity: 0.7;
        background: $black;
      }
    }
  }
}

.voyageId_invalid {
  .Flyout__content {
    width: 60%;
    padding: 20px 120px;

    @include media-breakpoint-down(md) {
      padding: 20px 60px;
    }

    @include media-breakpoint-down(sm) {
      top: 20%;
      left: 10%;

      width: 80%;
      height: 67%;
      padding: 20px;
    }
  }

  .ModalContent__body {
    padding-bottom: 20px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 40px;
    }
  }

  h1 {
    padding-bottom: 20px;
    font-size: 30px;
    line-height: 36px;
  }

  .btn {
    width: 50%;
    margin: 0 auto 80px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 60px;
    }
  }
}
